import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { onCloseModal } from '@utils'
import { Layout, Table, Typography, Button, Row, Col, Space } from 'antd'
import moment from 'moment/moment'
import { useTranslation } from 'react-i18next'
const { Header, Content } = Layout

const { Title, Text } = Typography

const SelectProcess = props => {
  const { tableData, setSelectedRowKeys, selectedRowKeys, loading } = props
  const dispatch = useDispatch()
  const modals = useSelector(state => state.app.modals)
  const index = modals.findIndex(el => el.type === 'select-process-user')
  const [selected, setSelected] = useState()
  const { t } = useTranslation()

  const onClose = () => {
    onCloseModal(dispatch, modals, 'select-process-user')
  }

  const columns = [
    {
      title: t('staff.selectProcessUser.table.column.name'),
      dataIndex: 'name'
    },
    {
      title: t('staff.selectProcessUser.table.column.stages'),
      dataIndex: 'stagesCount'
    },
    {
      title: t('staff.selectProcessUser.table.column.time'),
      dataIndex: 'created_at',
      render: text => {
        return moment(text).format('DD.MM.YYYY')
      }
    }
  ]

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys)

      setSelected(selectedRowKeys)
    },
    selectedRowKeys: selected || selectedRowKeys
  }

  return (
    <div className="modal__popup" style={{ zIndex: 1000 + index * 2 }}>
      <Layout style={{ minHeight: '100vh' }}>
        <Header style={{ marginBottom: '24px' }}>
          <Row justify="space-between" align="middle">
            <Col>
              <Title level={4}>{t('staff.selectProcessUser.title')}</Title>
            </Col>
            <Col>
              <Space size={20}>
                <Button onClick={onClose}>{t('staff.selectProcessUser.сlose')}</Button>
              </Space>
            </Col>
          </Row>
        </Header>

        <div
          style={{
            overflowY: 'auto',
            overflowX: 'hidden',
            height: 'calc(100vh - 128px)'
          }}
        >
          <Content
            style={{
              maxWidth: '1400px',
              margin: '0 auto',
              width: '100%',
              backgroundColor: '#FFFFFF',
              borderRadius: '6px'
            }}
          >
            <Table
              loading={loading}
              rowSelection={{
                type: 'checkbox',
                ...rowSelection
              }}
              rowKey="id"
              columns={columns}
              dataSource={tableData}
              pagination={false}
            />
          </Content>
        </div>
      </Layout>
    </div>
  )
}

export default SelectProcess
