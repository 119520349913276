import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {useLocation, useNavigate} from 'react-router-dom'

import { authorizationLayer, SET_APP, GET_DATA } from '@store/actions/methods'

import Loading from './components/common/Loading'

import useNotification from '@hooks/useNotification'

import { YMInitializer } from 'react-yandex-metrika';

import Routers from './router/'

import 'antd/dist/antd.less'

import SelectUsers from '@pages/Process/modals/SelectUsers'
import SelectForms from '@pages/Process/modals/SelectForms'
import SelectProcess from '@pages/Process/modals/SelectProcess'
import SelectProcessUser from '@pages/Staff/modals/SelectProcess'

import { ConfigProvider } from 'antd'
import ruRU from 'antd/es/locale/ru_RU'
import enUS from 'antd/es/locale/en_US'
import i18n from './i18n'
import moment from 'moment'
import 'moment/locale/ru'

export const AppContext = React.createContext()
moment.locale(i18n.language)

const App = (props) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()

  const { loader } = useSelector(state => state.app)
  const { token } = useSelector(state => state.app)
  const { user } = useSelector(state => state.app)
  const [mode, setMode] = useState('desktop')
  const notification = useNotification(true)
  const modals = useSelector(state => state.app.modals)
  const [codeYM, setCodeYM] = useState(null)
  const [antdProviderLang, setAntdProviderLang] = useState(enUS)
  const [_user, _setUser] = useState(null)

  useEffect(() => {
    if (user) {
      if (user?.yandex_metrics_code) {
        setCodeYM(user.yandex_metrics_code)
      }
      if (!_user || _user.id !== user.id) {
        _setUser(user)
      }
    } else {
      _setUser(null)
    }
  }, [user])

  useEffect(() => {
    const init = async () => {
      dispatch(SET_APP(['loader'], true))
      try {
        if (token !== undefined){
          dispatch(GET_DATA('users/modules/categories', 'moduleCategories', false))
          dispatch(GET_DATA('users/modules', 'allModules', false))
        }
        dispatch(SET_APP(['modals'], []))
      } catch (e) {
        console.log(e.message)
      } finally {
        dispatch(SET_APP(['loader'], false))
      }
    }
    init()
  }, [location.pathname === '/staff' || location.pathname === '/'])

  useEffect(() => {
    if(token === undefined) navigate('/login')
  }, [token])

  const getTranslate = async () => {
    await dispatch(
      authorizationLayer({
        url: 'translations',
      })
    ).then(({ data }) => {
      if (data.success) {
        console.log(data.data)
        i18n.addResourceBundle(
          i18n.language,
          'translation',
          data.data,
          true,
          true
        )
      }
    })
  }

  useEffect(() => {
    if (_user) {
      const getData = async () => {
        dispatch(SET_APP(['loader'], true))

        try {
          const { data } = await dispatch(
            authorizationLayer({
              url: `dealers/info`,
            })
          )

          dispatch(SET_APP(['permissions'], data.permissions))

          if (_user.locale) {
            const langs = [ruRU, enUS]
            const lang = langs.find((i) => i.locale === _user.locale)

            if (lang) setAntdProviderLang(lang)
            await i18n.changeLanguage(_user.locale)
            moment.locale(_user.locale)
          }

         await getTranslate()
        } catch (e) {
          console.log(e.message)
        } finally {
          dispatch(SET_APP(['loader'], false))
        }
      }
      getData()
    }
  }, [_user])

  return (
    <ConfigProvider locale={antdProviderLang}>
      <React.Suspense fallback={<Loading />}>
        {codeYM !== null ? <YMInitializer accounts={[codeYM]} options={{webvisor: true}} version="2"/> : null}
        {modals?.map(modal => {
          if (modal.type === 'select-users') {
            return (
              <SelectUsers
                key={modal.id}
                id={modal.id}
                users={modal.users}
                selectUsers={modal.selectUsers}
              />
            )
          } else if (modal?.type === 'select-forms') {
            return (
              <SelectForms
                key={modal.id}
                id={modal.id}
                form={modal.form}
                selectForm={modal.selectForm}
              />
            )
          } else if (modal?.type === 'select-process') {
            return (
              <SelectProcess
                key={modal.id}
                id={modal.id}
                process={modal.process}
                selectProcess={modal.selectProcess}
              />
            )
          } else if (modal?.type === 'select-process-user') {
            return (
              <SelectProcessUser
                key={modal.id}
                id={modal.id}
                tableData={modal.data}
                setSelectedRowKeys={modal.setSelectedRowKeys}
                selectedRowKeys={modal.selectedRowKeys}
                loading={modal.loading}
              />
            )
          }
        })}
        {loader ? <Loading /> : ''}
        <AppContext.Provider value={{ mode, setMode }}>
          <Routers notification={notification} />
        </AppContext.Provider>
      </React.Suspense>
    </ConfigProvider>
  )
}
export default App
