import React, {useEffect, useState} from 'react'

import {useDispatch, useSelector} from "react-redux";

import {authorizationLayer, SET_APP} from "@store/actions/methods";

import queryString from "query-string";

import {
    Layout,
    Table,
    Typography,
    Button,
    Row,
    Col,
    Space,
    Tag,
    Input
} from 'antd';

import _ from "lodash";

const {Header, Content} = Layout;

const {Search} = Input;

const {Title} = Typography;

const SelectUsers = (props) => {
    const dispatch = useDispatch();
    const modals = useSelector(state => state.app.modals);
    const index = modals.findIndex(el => el.type === 'select-users');

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [disableSubmit, setDisableSubmit] = useState(true)
    const [selectedRowsKeys, setSelectedRowsKeys] = useState([])

    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        pageSizeOptions: [10, 50, 100],
    });

    const getData = async () => {
        setLoading(true)
        const q = {
            page: pagination.current,
            take: pagination.pageSize,
            sortElement: 'name'
        };

        const query = queryString.stringify(q);

        const {data} = await dispatch(
            authorizationLayer({url: `users?${query}`})
        );

        setData(data);
        setLoading(false)
    };

    useEffect(() => {
        getData();

        if (props.users.length > 0) {
            setDisableSubmit(false)
            setSelectedRowsKeys(props.users)
        }
    }, [dispatch, pagination]);


    useEffect(() => {
        if (selectedRowsKeys?.length > 0) {
            setDisableSubmit(false)
        } else {
            setDisableSubmit(true)
        }

    }, [selectedRowsKeys]);

    const onClose = () => {
        dispatch(
            SET_APP(
                ['modals'],
                _.filter(modals, c => c.type !== 'select-users')
            )
        )
    }

    const onSubmit = () => {
        props.selectUsers(selectedRowsKeys)
        onClose()
    }

    const onChange = (pagination) => {
        setPagination(pagination);
    };

    const handleSearch = async value => {
        console.log(selectedRowsKeys)
        if (value) {
            setLoading(true)
            const q = {
                page: pagination.current,
                take: pagination.pageSize,
                sortElement: 'name'
            };
            const query = queryString.stringify(q);
            await dispatch(
                authorizationLayer({
                    url: `users?${value ? `keyword=${value.trim()}` : ''}&${query}`,
                    method: 'GET'
                })
            )
                .then(res => {
                    setData(res.data);
                })
                .then(() => setLoading(false))
        } else {
            getData()
        }
    }

    const columns = [
        {
            title: 'ФИО',
            dataIndex: 'full_name'
        },
        {
            title: 'Должность',
            dataIndex: 'position',
            render: pos => (
                <Tag>
                    {pos && pos.name}
                </Tag>
            ),
        }
    ]

    const onSelect = (record, checked) => {
        const exists = _.find(selectedRowsKeys, (o) => o.id === record.id)
        if (!exists) {
            setSelectedRowsKeys([...selectedRowsKeys, record])
        } else {
            setSelectedRowsKeys(_.filter(selectedRowsKeys, (o) => o.id !== record.id))
        }
    }

    const onSelectAll = (selectedStatus, selectedRows, changeRows) => {
        if (selectedStatus) {
            setSelectedRowsKeys([...selectedRowsKeys, ...changeRows])
        } else {
            setSelectedRowsKeys(_.xorBy(selectedRowsKeys, changeRows, 'id'))
        }
    }

    const rowSelection = {
        selectedRowKeys: selectedRowsKeys.map((o) => o.id),
        onSelect: onSelect,
        onSelectAll: onSelectAll,
    }

    return (
        <div className="modal__popup" style={{zIndex: 1000 + index * 2}}>
            <Layout style={{minHeight: '100vh'}}>
                <Header style={{marginBottom: '24px'}}>
                    <Row justify="space-between" align="middle">
                        <Col>
                            <Title level={4}>Выберите сотрудников</Title>
                        </Col>
                        <Col>
                            <Space size={20}>
                                <Button onClick={onClose}>Закрыть</Button>
                                <Button
                                    type="primary"
                                    onClick={onSubmit}
                                    disabled={disableSubmit}
                                >
                                    Сохранить
                                </Button>
                            </Space>
                        </Col>
                    </Row>
                </Header>

                <div
                    style={{
                        overflowY: 'auto',
                        overflowX: 'hidden',
                        height: 'calc(100vh - 128px)'
                    }}
                >
                    <Content
                        style={{
                            maxWidth: '1400px',
                            margin: '0 auto',
                            width: '100%',
                            borderRadius: "6px",
                        }}
                    >
                        <Row justify="space-between" align="middle" style={{marginBottom: "20px"}}>
                            <Col flex="400px">
                                <Search
                                    placeholder="Поиск"
                                    onChange={_.debounce(e => handleSearch(e.target.value), 700)}
                                    onSearch={_.debounce(handleSearch, 700)}
                                    allowClear
                                    style={{maxWidth: 400}}
                                />
                            </Col>
                        </Row>
                        <Table
                            loading={loading}
                            rowKey="id"
                            rowSelection={{
                                type: "checkbox",
                                ...rowSelection
                            }}
                            columns={columns}
                            dataSource={data.data}
                            onChange={onChange}
                            pagination={{
                                pagination,
                                total: data.total
                            }}
                        />
                    </Content>
                </div>
            </Layout>
        </div>
    )
}

export default SelectUsers
