import Header from '@components/common/Header'
import PageHeading from '@components/common/PageHeading'
import SearchBlock from '@components/common/SearchBlock'

import React, { useState, useEffect } from 'react'

import _ from 'lodash'

import { useLocation, Outlet, useNavigate } from 'react-router-dom'

import { useDispatch, useSelector } from 'react-redux'

import { GET_DATA } from '@store/actions/methods'

import { Layout, Space } from 'antd'

import { useTranslation } from 'react-i18next'

const { Content } = Layout

const MainLayout = ({ notification }) => {
  // useState, routing
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const [pageSize, setPageSize] = useState()
  const [pageNumber, setPageNumber] = useState()
  const [sorterField, setSorterField] = useState()
  const [sorterOrd, setSorterOrd] = useState('ASC')
  const [searchValue, setSearchValue] = useState()
  const { users } = useSelector((state) => state.app)
  const { t } = useTranslation()

  const pageHeaderData = [
    {
      title: t('staff.title'),
      subtitle: t('staff.subtitle'),
      url: '/staff',
      goBackStatus: false,
      goBackUrl: '',
      searchStatus: true,
      id: 1,
    },
    {
      title: t('processes.title'),
      subtitle: t('processes.subtitle'),
      url: '/process',
      goBackStatus: false,
      goBackUrl: '',
      searchStatus: false,
      id: 2,
    },
    // {
    //   title: 'Новый процесс',
    //   subtitle: 'Выберите подходящий шаблон',
    //   url: '/process/add',
    //   goBackStatus: true,
    //   goBackUrl: '/process',
    //   searchStatus: false,
    //   id: 3
    // },
    {
      title: t('forms.title'),
      subtitle: t('forms.subtitle'),
      url: '/form',
      goBackStatus: false,
      goBackUrl: '',
      searchStatus: false,
      id: 4,
    },
    {
      title: t('stock.title'),
      subtitle: t('stock.subtitle'),
      url: '/stock',
      goBackStatus: false,
      goBackUrl: '',
      searchStatus: false,
      id: 5,
    },
    {
      title: 'Услуги',
      subtitle: 'Управляйте услугами, предоставляемые специалистами цеха',
      url: '/services',
      goBackStatus: false,
      goBackUrl: '',
      searchStatus: false,
      id: 6,
    },
    {
      title: 'Клиенты',
      subtitle:
        'Сервис предназначенный для управления клиентскими данными с заявок',
      url: '/clients',
      goBackStatus: false,
      goBackUrl: '',
      searchStatus: false,
      id: 7,
    },
    {
      title: t('analytics.title'),
      subtitle: t('analytics.subtitle'),
      url: '/analytics',
      goBackStatus: false,
      goBackUrl: '',
      searchStatus: false,
      id: 8,
    },
  ]

  // Func

  const contextData = {
    cardEmptyStyle: {
      height: 616,
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
    },
    notification: notification,
    queryObj: {
      pageSizeFunc: setPageSize,
      pageNumberFunc: setPageNumber,
      sorterFieldEl: sorterField,
      sorterFieldFunc: setSorterField,
      sorterOrdEl: sorterOrd,
      sorterOrdFunc: setSorterOrd,
      searchEl: searchValue,
      pageNumber,
      pageSize,
    },
  }

  useEffect(() => {
    setSearchValue('')
  }, [location])

  const currentPageHeader = pageHeaderData.find(
    (item) => item.url === location.pathname,
  )

  const handleSearch = (value, pageS = pageSize) => {
    if (currentPageHeader.id === 1) {
      dispatch(
        GET_DATA(
          'users',
          'users',
          true,
          pageS,
          1,
          value.trim(),
          sorterField,
          sorterOrd,
        ),
      )
      setSearchValue(value.trim())
    } else if (currentPageHeader.id === 2) {
      dispatch(
        GET_DATA(
          'processes',
          'processes',
          true,
          pageS,
          1,
          value.trim(),
          sorterField,
          sorterOrd,
        ),
      )
      setSearchValue(value.trim())
    } else notification()
  }

  const goBack = () => navigate(currentPageHeader.goBackUrl)

  return (
    <>
      <Header />
      {location.pathname !== '/' ? (
        <Layout>
          {currentPageHeader && (
            <PageHeading
              title={currentPageHeader.title}
              subtitle={currentPageHeader.subtitle}
              goBack={goBack}
              status={currentPageHeader.goBackStatus}
            />
          )}
          <div style={{ overflowY: 'auto', height: 'calc(100vh - 136px)' }}>
            <Content>
              <Space
                direction="vertical"
                size={20}
                style={{
                  width: '100%',
                  marginTop:
                    currentPageHeader && currentPageHeader.searchStatus && 20,
                }}
              >
                {currentPageHeader && currentPageHeader.searchStatus && (
                  <SearchBlock onSearch={_.debounce(handleSearch, 500)} />
                )}
                <Outlet context={contextData} />
              </Space>
            </Content>
          </div>
        </Layout>
      ) : (
        <Layout>
          <Content>
            <Outlet context={notification} />
          </Content>
        </Layout>
      )}
    </>
  )
}

export default MainLayout
