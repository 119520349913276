import { Navigate, Outlet, useLocation } from 'react-router'
import { useSelector } from 'react-redux'

const ProtectedRoute = () => {
    const { token } = useSelector((state) => state.app)
    const location = useLocation()

    if (!token) {
        return <Navigate to='/login' replace state={{ from: location }} />
    }

    return <Outlet />
}

export default ProtectedRoute