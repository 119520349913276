import React, { useState } from 'react'

import { Link, Outlet } from 'react-router-dom'

import { Layout, Button, Row, Col, Typography, Space } from 'antd'

const { Header, Content } = Layout

const { Title } = Typography

const ClientsSettingLayout = ({ notification }) => {
  // useState

  return (
    <>
      <Header className="fixed-container">
        <Row justify="space-between" align="middle">
          <Col>
            <Title level={4} style={{ fontWeight: 500 }}>
              Настройка даннных клиента
            </Title>
          </Col>
          <Col>
            <Space size={20}>
              <Button>
                <Link to="/clients">Отмена</Link>
              </Button>
              <Button type="primary">
                <Link to="/clients">Сохранить</Link>
              </Button>
            </Space>
          </Col>
        </Row>
      </Header>
      <Layout>
        <Content>
          <Outlet context={notification} />
        </Content>
      </Layout>
    </>
  )
}

export default ClientsSettingLayout
