import React, { useState } from 'react'

import { Outlet, useNavigate } from 'react-router-dom'

import { Layout, Button, Row, Col, Typography, Space, Modal } from 'antd'
import { authorizationLayer, SET_APP } from '@store/actions/methods'
import { useDispatch } from 'react-redux'
import { setProcessEdit } from '@store/actions/components/process'
import i18n from '../i18n'

import { useTranslation } from 'react-i18next'

const { Header, Content } = Layout

const { Title } = Typography

const ProcessSettingsLayout = ({ notification }) => {
    const dispatch = useDispatch()
    const [disabledStatus, setDisabledStatus] = useState(false)
    const [processData, setProcessData] = useState([])
    const [type, setType] = useState([])
    const [loading, setLoading] = useState(false)
    const [deleteElements, setDeleteElements] = useState([])
    const navigate = useNavigate()

    const { t } = useTranslation()

    const treeData = [{
        key: '1',
        title: t('processes.treeData.process'),
        canEditClientCard: true,
        isMain: true,
        name: t('processes.treeData.process'),
        showClientCard: true,
        userIds: [],
        children: [
            {
                key: '1-1',
                title: t('processes.treeData.stage'),
                cancelReasons: [],
                newCancelReasons: [],
                children: [
                    {
                        key: '1-1-1',
                        title: t('processes.treeData.step'),
                        description: '',
                        required: true,
                        module: '',
                        settings: {}
                    }
                ]
            }
        ]
    }]

    const validateProcess = (process) => {
        let error = {
            status: true,
            message: ''
        }
        if (process.stages.length > 0){
            process.stages.forEach(item => {
                if(item.modules.length === 0){
                    error.status = false
                    error.message = t('processes.validation.stage') + ': "' + item.name + '" ' + t('processes.validation.noSteps')
                } else {
                    item.modules.forEach(module => {
                        if (Object.keys(module.settings).length == 0 || Object.keys(module.component).length == 0){
                            error.status = false
                            error.message = t('processes.validation.stage') + ": " + item.name + ", " + t('processes.validation.step') + ": " + module.name + " " + t('processes.validation.noSettingsSelected')
                        } else {

                        }
                    })
                }
            })
        } else {
            error.message = t('processes.validation.notFilled')
        }

        if (process.userIds.length === 0 || process.userIds === undefined) {
            error.status = false
            error.message = t('processes.validation.notUsers')
        }

        console.log(error)
        return error
    }

    const errorModal = (message) => {
        Modal.error({
            centered: true,
            title: t('processes.validation.notSave'),
            content: (
              <div>{message}</div>
            ),
            okText: t('processes.validation.back'),
            maskClosable: true,
        })
    }

    const submit = async () => {
        let process = {}
        let old_stages = []
        let stages = []
        dispatch(SET_APP(['loader'], true))

        if (type === 'add') {
            processData[0].children?.forEach(item => {
                old_stages.push(item)
            })

            old_stages.forEach(item => {
                let steps = []
                item.children?.forEach(step => {
                    steps.push({
                        name: step.title,
                        description: step.description,
                        order: +step.key.substr(4, 5),
                        settings: step.settings,
                        isRequired: step.required,
                        component: {
                            id: step.module,
                        },
                    })
                })
                let arrCancelReasons = []

                item.cancelReasons.forEach(el => arrCancelReasons.push({ id: el.id }))

                stages.push({
                    name: item.title,
                    order: +item.key.substr(2, 3),
                    description: '',
                    cancelReasons: arrCancelReasons,
                    newCancelReasons: item.newCancelReasons,
                    modules: steps,
                })
            })

            process.stages = stages
            process.name = processData.name !== undefined ? processData.name : t('processes.treeData.process')
            process.isMain = processData.isMain !== undefined ? processData.isMain : true
            process.showClientCard = processData.showClientCard !== undefined ? processData.showClientCard : true
            process.canEditClientCard = processData.canEditClientCard !== undefined ? processData.canEditClientCard : true
            process.userIds = processData.userIds !== undefined ? processData.userIds : []
        } else {
            processData.children?.forEach(item => {
                old_stages.push(item)
            })

            old_stages.forEach(item => {
                let steps = []

                item.children?.forEach(step => {
                    let new_step = {
                        id: step.id ? step.id : '',
                        name: step.title,
                        description: step.description,
                        order: +step.key.substr(4, 5),
                        settings: step.settings,
                        isRequired: step.required,
                        component: {
                            id: step.module,
                        },
                    }

                    if (new_step.id === '') {
                        delete new_step.id
                    }

                    steps.push(new_step)
                })
                let arrCancelReasons = []

                item.cancelReasons.forEach(el => arrCancelReasons.push({ id: el.id }))

                let new_stage = {
                    id: item.id ? item.id : '',
                    name: item.title,
                    order: +item.key.substr(2, 3),
                    description: '',
                    cancelReasons: arrCancelReasons,
                    newCancelReasons: item.newCancelReasons,
                    modules: steps,
                }

                if (new_stage.id === '') {
                    delete new_stage.id
                }

                stages.push(new_stage)
            })

            process.id = processData.id
            process.stages = stages
            process.name = processData.name
            process.isMain = processData.isMain
            process.showClientCard = processData.showClientCard
            process.canEditClientCard = processData.canEditClientCard
            process.userIds = processData.userIds
        }

        let error = validateProcess(process)

        if (error.status) {
            try {
                setLoading(true)
                const request = await dispatch(
                  authorizationLayer({
                      url: `/processes`,
                      method: type === 'add' ? 'post' : 'patch',
                      data: process,
                      headers: {
                          ['Accept-Language']: i18n.language,
                          ['Accept-type']: 'admin'
                      }
                  }),
                )

                request.data.data.stages?.forEach(stage => {
                    stage.modules.forEach(module => {
                        let formId = Object.prototype.hasOwnProperty.call(module.settings, 'formId')
                        if (formId) {
                            dispatch(
                              authorizationLayer({
                                  url: `/forms/binding`,
                                  method: 'post',
                                  headers: {
                                      ['Accept-Language']: i18n.language,
                                      ['Accept-type']: 'admin'
                                  },
                                  data: {
                                      form_id: module.settings.formId,
                                      process_id: request.data.data.id,
                                      module_id: module.id,
                                  },
                              }),
                            )
                        }
                    })
                })

                if (type === 'edit') {
                    if (deleteElements.length > 0) {
                        deleteElements.forEach(el => {
                            if (Object.keys(el).includes('children')) {
                                dispatch(authorizationLayer({
                                    url: `processes/stages/${el.id}`,
                                    method: 'DELETE',
                                    headers: {
                                        ['Accept-Language']: i18n.language,
                                        ['Accept-type']: 'admin'
                                    }
                                }))
                            } else {
                                dispatch(authorizationLayer({
                                    url: `processes/modules/${el.id}`,
                                    method: 'DELETE',
                                    headers: {
                                        ['Accept-Language']: i18n.language,
                                        ['Accept-type']: 'admin'
                                    }
                                }))
                            }
                        })
                    }
                }

                navigate('/process')
                dispatch(setProcessEdit(treeData))
                setLoading(false)

            } catch (request) {
                navigate('/process')
                dispatch(setProcessEdit(treeData))
                setLoading(false)
            }
        } else {
            errorModal(error.message)
        }

        dispatch(SET_APP(['loader'], false))
    }

    const cancel = () => {
        Modal.confirm({
            title: t('common.header.modal.cancel.title'),
            content: t('common.header.modal.cancel.subtitle'),
            cancelText: t('common.header.modal.cancel.no'),
            okText: t('common.header.modal.cancel.yes'),
            okType: 'danger',
            onCancel() {
            },
            onOk() {
                navigate('/process')
                dispatch(setProcessEdit(treeData))
            },
        })
    }

    const contextData = {
        disabledStatusEdit: setDisabledStatus,
        notification: notification,
        setProcessData: setProcessData,
        processData: processData,
        setType: setType,
        deleteElements: deleteElements,
        setDeleteElements: setDeleteElements,
    }

    return (
      <>
          <Header className='fixed-container'>
              <Row justify='space-between' align='middle'>
                  <Col>
                      <Title level={4} style={{ fontWeight: 500 }}>
                          {t('processes.edit.header.title')}
                      </Title>
                  </Col>
                  <Col>
                      <Space size={20}>
                          <Button onClick={() => cancel()}>
                              {t('processes.edit.header.btn.cancel')}
                          </Button>
                          <Button type='primary' disabled={disabledStatus} onClick={() => submit()}>
                              {t('processes.edit.header.btn.save')}
                          </Button>
                      </Space>
                  </Col>
              </Row>
          </Header>
          <Layout>
              <Content>
                  <Outlet loading={loading} context={contextData} />
              </Content>
          </Layout>
      </>
    )
}

export default ProcessSettingsLayout