import React, { useState } from 'react'

import { Link, Outlet, useNavigate } from 'react-router-dom'

import { useDispatch } from 'react-redux'

import {
  authorizationLayer,
  POST_DATA,
  UPDATE_EDIT_DATA
} from '@store/actions/methods'

import { Layout, Button, Row, Col, Typography, Space, notification } from 'antd'
import { Modal } from 'antd'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

const { Header, Content } = Layout

const { Title } = Typography

const SettingsLayout = ({ ...props }) => {
  // useState
  const dispatch = useDispatch()
  const [currentTitle, setCurrentTitle] = useState('')
  const [goBackAdress, setGoBackAdress] = useState('/')
  const [urlToPost, setUrlToPost] = useState('')
  const [dataToPost, setDataToPost] = useState({})
  const [redirectUrlToPost, setRedirectUrlToPost] = useState('')
  const [disabledStatus, setDisabledStatus] = useState(true)
  const [forEditStatus, setForEditStatus] = useState(false)
  const [forProcessData, setForProcessData] = useState([])
  const navigate = useNavigate()

  const { t } = useTranslation()

  // Func
  const renameTitle = useCallback(
    title => {
      setCurrentTitle(title)
    },
    [setCurrentTitle]
  )

  const settingGoBackAdress = useCallback(
    adress => {
      setGoBackAdress(adress)
    },
    [setGoBackAdress]
  )

  const updateNotification = () => {
    notification.info({
      message: 'Поменяйте пароль через почту',
      duration: 3
    })
  }

  const saveSettings = (
    url = urlToPost,
    formData = dataToPost,
    redirectUrl = redirectUrlToPost
  ) => {
    if (forEditStatus) {
      dispatch(UPDATE_EDIT_DATA(url, formData)).then(res => {
        if (res.data.email_changed) {
          updateNotification()
        }
        if (res.status === 200) {
          navigate(redirectUrl)
        }

        if (url.includes('users')) {
          dispatch(
            authorizationLayer({
              url: `processes/process-user/add-processes-to-user`,
              method: 'POST',
              data: {
                userId: res.data.id,
                processIds: forProcessData
              }
            })
          )
        }
      })
    } else {
      dispatch(POST_DATA(url, formData)).then(res => {
        if (res.status === 201) {
          navigate(redirectUrl)
          if (typeof redirectUrl === 'function') {
            navigate(redirectUrl(res))
          } else {
            navigate(redirectUrl)
          }
        }

        if (url === 'users') {
          dispatch(
            authorizationLayer({
              url: `processes/process-user/add-processes-to-user`,
              method: 'POST',
              data: {
                userId: res.data.id,
                processIds: forProcessData
              }
            })
          )
        }
      })
    }
  }

  const contextData = {
    renameTitleFunc: renameTitle,
    goBackFunc: settingGoBackAdress,
    notification: props.notification,
    urlUpdate: setUrlToPost,
    dataUpdate: setDataToPost,
    disabledStatusEdit: setDisabledStatus,
    redirectFuncUpdate: setRedirectUrlToPost,
    editFunc: setForEditStatus,
    setForProcessData: setForProcessData
  }

  const beforeCancel = () => {
    Modal.confirm({
      title: t('common.header.modal.cancel.title'),
      content: t('common.header.modal.cancel.subtitle'),
      cancelText: t('common.header.modal.cancel.no'),
      okText: t('common.header.modal.cancel.yes'),
      okType: 'danger',
      onOk() {
        navigate(goBackAdress)
      }
    })
  }

  return (
    <>
      <Header className="fixed-container">
        <Row justify="space-between" align="middle">
          <Col>
            <Title level={4} style={{ fontWeight: 500 }}>
              {currentTitle}
            </Title>
          </Col>
          <Col>
            <Space size={20}>
              <Button onClick={() => beforeCancel()}>{t('common.header.cancel')}</Button>
              <Button
                type="primary"
                disabled={disabledStatus}
                onClick={() => saveSettings()}
              >
                {t('common.header.save')}
              </Button>
            </Space>
          </Col>
        </Row>
      </Header>
      <Layout>
        <div
          style={{
            overflowY: 'auto',
            height: 'calc(100vh - 64px)',
            marginTop: 64
          }}
        >
          <Content
            style={{
              padding: '0 40px',
              maxWidth: 1480,
              margin: '24px auto 0 ',
              width: '100%',
              paddingBottom: 94
            }}
          >
            <Outlet context={contextData} />
          </Content>
        </div>
      </Layout>
    </>
  )
}

export default SettingsLayout
