import _ from 'lodash'
import { SET_APP, PUSH_APP } from '@store/actions/methods'

export const sortAlphaNumeric = (a, b) => {
  a = _.toString(a)
  b = _.toString(b)

  return a.localeCompare(b)
}

export const openModals = (dispatch, data) => {
  dispatch(PUSH_APP(['modals'], data))
}

export const onCloseModal = (dispatch, modals, type) => {
  dispatch(
    SET_APP(
      ['modals'],
      _.filter(modals, c => c.type !== type)
    )
  )
}

export const numberWithSpaces = x => {
  if (x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
  } else return 0
}

export const phoneFormat = (x) => {
  if (x) {
    return x
      .toString()
      .replace(/\D/g, '')
      .replace(/(\d{1})(\d{1,5})/, '$1 $2')
      .replace(/(\d{3})(\d{1,5})/, '$1 $2')
      .replace(/(\d{5})(\d{1,5})/, '$1 $2')
      .replace(/(\d{3})(\d{1,5})/, '$1 $2')
  } else return null
}
