import React, {useEffect, useState} from 'react'

import {useDispatch, useSelector} from "react-redux";

import {authorizationLayer, SET_APP} from "@store/actions/methods";

import {
    Layout,
    Table,
    Typography,
    Button,
    Row,
    Col,
    Space,
} from 'antd';

import _ from "lodash";
import queryString from "query-string";

const {Header, Content} = Layout;

const {Title, Text} = Typography;

const SelectForms = (props) => {
    // useState
    const dispatch = useDispatch();
    const modals = useSelector(state => state.app.modals);
    const index = modals.findIndex(el => el.type === 'select-forms');

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [selectedRows, setSelectedRows] = useState([])
    const [selectedRowsKeys, setSelectedRowsKeys] = useState([])
    const [disableSubmit, setDisableSubmit] = useState(true)

    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        pageSizeOptions: [10, 50, 100],
    });

    const getData = async () => {
        setLoading(true)
        const q = {
            page: pagination.current,
            take: pagination.pageSize,
            sortElement: 'created_at'
        };

        const query = queryString.stringify(q);

        const {data} = await dispatch(
            authorizationLayer({url: `forms?${query}`})
        );

        setData(data);
        setLoading(false)
    };

    useEffect(() => {
        getData();

        if (props.form.length > 0) {
            setDisableSubmit(false)
            setSelectedRowsKeys(props.form)
        } else {
            setDisableSubmit(true)
        }
    }, [dispatch, pagination]);

    const onClose = () => {
        dispatch(
            SET_APP(
                ['modals'],
                _.filter(modals, c => c.type !== 'select-forms')
            )
        )
    }

    const onSubmit = () => {
        props.selectForm(selectedRowsKeys)
        onClose()
    }

    const onChange = (pagination) => {
        setPagination(pagination);
    };

    const onSelect = (record, checked) => {
        setSelectedRowsKeys([record])
        if ([record].length > 0) {
            setDisableSubmit(false)
        } else {
            setDisableSubmit(true)
        }
    }

    const columns = [
        {
            title: 'Название',
            dataIndex: 'name'
        },
        {
            title: 'Кол-во полей',
            dataIndex: 'fieldCount',
            width: 300
        },
        {
            title: 'Действие',
            dataIndex: 'action',
            width: 160
            // render: link => (
            //   <Link to={link} onClick={notification}>
            //     Посмотреть
            //   </Link>
            // )
        }
    ]

    // const rowSelection = {
    //     selectedRowKeys: selectedRowsKeys,
    //     onChange: (selectedRowKeys, selectedRows) => {
    //         if (selectedRows?.length > 0 ){
    //             setDisableSubmit(false)
    //         } else {
    //             setDisableSubmit(true)
    //         }
    //
    //         setSelectedRows(selectedRows)
    //         setSelectedRowsKeys(selectedRowKeys)
    //     },
    // }

    const rowSelection = {
        selectedRowKeys: selectedRowsKeys.map((o) => o.id),
        onSelect: onSelect,
    }

    return (
        <div className="modal__popup" style={{zIndex: 1000 + index * 2}}>
            <Layout style={{minHeight: '100vh'}}>
                <Header style={{marginBottom: '24px'}}>
                    <Row justify="space-between" align="middle">
                        <Col>
                            <Title level={4}>Выберите форму</Title>
                        </Col>
                        <Col>
                            <Space size={20}>
                                <Button onClick={onClose}>Закрыть</Button>
                                <Button
                                    type="primary"
                                    onClick={onSubmit}
                                    disabled={disableSubmit}
                                >
                                    Сохранить
                                </Button>
                            </Space>
                        </Col>
                    </Row>
                </Header>

                <div
                    style={{
                        overflowY: 'auto',
                        overflowX: 'hidden',
                        height: 'calc(100vh - 128px)'
                    }}
                >
                    <Content
                        style={{
                            maxWidth: '1400px',
                            margin: '0 auto',
                            width: '100%',
                            backgroundColor: "#FFFFFF",
                            borderRadius: "6px",
                        }}
                    >
                        <Table
                            loading={loading}
                            rowSelection={{
                                type: "radio",
                                ...rowSelection
                            }}
                            rowKey='id'
                            columns={columns}
                            dataSource={data.data}
                            onChange={onChange}
                            pagination={{
                                pagination,
                                total: data.total
                            }}
                        />
                    </Content>
                </div>
            </Layout>
        </div>
    )
}

export default SelectForms