import Header from '@components/common/Header'
import React, { useState} from 'react'
import {Outlet} from 'react-router-dom'
import { Layout, Space } from 'antd'
const { Content } = Layout

const TasksLayout = ({ notification }) => {
    // useState, routing
    const [pageSize, setPageSize] = useState()
    const [pageNumber, setPageNumber] = useState()
    const [sorterField, setSorterField] = useState()
    const [sorterOrd, setSorterOrd] = useState('ASC')
    const [searchValue, setSearchValue] = useState()
    // Func

    const contextData = {
        cardEmptyStyle: {
            height: 616,
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
        },
        notification: notification,
        queryObj: {
            pageSizeFunc: setPageSize,
            pageNumberFunc: setPageNumber,
            sorterFieldEl: sorterField,
            sorterFieldFunc: setSorterField,
            sorterOrdEl: sorterOrd,
            sorterOrdFunc: setSorterOrd,
            searchEl: searchValue,
            pageNumber,
            pageSize,
        },
    }


    return (
      <>
          <Header />
            <Layout>
                <div style={{ overflowY: 'auto', height: 'calc(100vh - 14px)', paddingTop: 64 }}>
                    <Content>
                        <Space
                          direction="vertical"
                          size={20}
                          style={{
                              width: '100%',
                          }}
                        >
                            <Outlet context={contextData} />
                        </Space>
                    </Content>
                </div>
            </Layout>
      </>
    )
}

export default TasksLayout
