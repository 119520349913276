import React from 'react'

import { Typography, Card, List } from 'antd'

import { Link } from 'react-router-dom'

const { Title, Text } = Typography

const Routing = ({ columnGrid, toggleCollapsed, ...props }) => {
  const setPermissionsStatus = id => {
    let permissionsStatus = false

    props.user_metadata?.permissions?.forEach(item => {
      if (item.app_module && item.app_module.id === id) {
        permissionsStatus = true
      }
    })

    return permissionsStatus
  }

  return (
    <>
      <List
        itemLayout="vertical"
        dataSource={props.moduleCategories}
        renderItem={(item, index) => (
          <List.Item
            style={{
              marginBottom:
                index + 1 === props.moduleCategories.length ? '0' : '20px'
            }}
          >
            <Title level={5} style={{ fontWeight: 500, marginBottom: 20 }}>
              {item.name}
            </Title>
            <List
              grid={{
                gutter: 20,
                column: columnGrid,
                xs: 1,
                sm: 1,
                md: 2
              }}
              dataSource={item.modules}
              renderItem={innerItem => (
                <List.Item style={{ marginBottom: 20 }}>
                  <Link
                    to={innerItem.url}
                    onClick={toggleCollapsed}
                    style={
                      innerItem.active && setPermissionsStatus(innerItem.id)
                        ? {
                            pointerEvents: 'auto',
                            cursor: 'pointer'
                          }
                        : {
                            pointerEvents: 'none',
                            cursor: 'default'
                          }
                    }
                  >
                    <Card
                      hoverable={
                        innerItem.active && setPermissionsStatus(innerItem.id)
                      }
                      bordered={false}
                      bodyStyle={{
                        minHeight: 122,
                        lineHeight: '14px'
                      }}
                      style={
                        innerItem.active && setPermissionsStatus(innerItem.id)
                          ? {
                              background: '#fff',
                              border: '1px solid #e8e8e8'
                            }
                          : {
                              background: '#F0F2F4',
                              border: '1px solid #F0F2F4'
                            }
                      }
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          marginBottom: 8
                        }}
                      >
                        <img
                          src={innerItem.icon_url}
                          alt=""
                          style={{
                            fontSize: '20px',
                            color:
                              innerItem.active &&
                              setPermissionsStatus(innerItem.id)
                                ? '#0989FF'
                                : ' rgba(41, 52, 82, 0.4)',
                            display: 'inline-block',
                            lineHeight: 0
                          }}
                        />
                        <Title
                          level={5}
                          style={{
                            fontWeight: 400,
                            marginBottom: 0,
                            marginLeft: 10,
                            color:
                              innerItem.active &&
                              setPermissionsStatus(innerItem.id)
                                ? ''
                                : 'rgba(41, 52, 82, 0.4)'
                          }}
                        >
                          {innerItem.name}
                        </Title>
                      </div>
                      <Text
                        style={{
                          fontSize: 12,
                          color: 'rgba(41, 52, 82, 0.4)'
                        }}
                      >
                        {innerItem.description}
                      </Text>
                    </Card>
                  </Link>
                </List.Item>
              )}
            ></List>
          </List.Item>
        )}
      ></List>
    </>
  )
}

export default Routing
