import React, {useEffect, useState} from 'react'

import {Outlet, useNavigate, useParams} from 'react-router-dom'

import {Layout, Button, Row, Col, Menu} from 'antd'
import {Typography, Space, Modal} from 'antd'
import {authorizationLayer} from '@store/actions/methods'
import {useInterval} from "@hooks/useInterval"
import {useDispatch} from 'react-redux'
import moment from "moment";
import _ from "lodash";

import { useTranslation } from 'react-i18next'

const {Header, Content} = Layout

const {Title} = Typography

const ReportLayout = () => {
    const {id} = useParams();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [marks, setMarks] = useState([])
    const [models, setModels] = useState([])
    const [modelsConfig, setModelsConfig] = useState([])
    const [info, setInfo] = useState({})
    const [config, setConfig] = useState({
        columns: [],
        rows: [],
        values: []
    })
    const [settings, setSettings] = useState({
        columns: [],
        rows: [],
        values: []
    });

    const today = moment().format('YYYY-MM')

    const [dates, setDates] = useState({
        from: today + '-01',
        to: moment().format('YYYY-MM-DD')
    })

    const [selectedMark, setSelectedMark] = useState(null)
    const [selectedModel, setSelectedModel] = useState(null)

    const [time, setTime] = useState(null)
    const [selectedTime, setSelectedTime] = useState(null)

    const { t } = useTranslation()

    const cancel = () => {
        Modal.confirm({
            title: t('analytics.tabs.reports.modal.title'),
            content: t('analytics.tabs.reports.modal.subtitle'),
            cancelText: t('analytics.tabs.reports.modal.no'),
            okText: t('analytics.tabs.reports.modal.yes'),
            okType: 'danger',
            onCancel() {
            },
            onOk() {
                navigate('/analytics')
            },
        })
    }

    const getInfoByReport = async () => {
        try {
            setLoading(true)

            const dataConfig = await dispatch(
              authorizationLayer({
                  url: `/analytics/reports/table/config`,
                  method: 'get'
              })
            )

            const dataSettings = await dispatch(
              authorizationLayer({
                  url: `/analytics/reports/${id}?from=${dates.from}&to=${dates.to}&markId=${selectedMark}&modelId=${selectedModel}`,
                  method: 'get'
              })
            )

            const dataProcess = await dispatch(
              authorizationLayer({
                  url: `/processes/show/${dataSettings.data.data.processId}`,
                  method: 'get'
              })
            )

            let formIds = []
            let fields = []

            dataProcess.data.data.stages.forEach(stage => {
                stage.modules.forEach(module => {
                    if (module.settings['formId'] !== undefined){
                        formIds.push(module.settings.formId)
                    }
                })
            })

            for (const form of formIds) {
                await dispatch(
                  authorizationLayer({
                      url: `/forms/${form}`,
                      method: 'get'
                  })
                ).then(res => {
                    console.log("data", res.data)
                    res.data.fields.forEach(field => {
                        if(field.type === 'select' || field.type === 'multi_select'){
                            console.log(field)
                            fields.push({
                                id: field.id,
                                code: 'FORMS',
                                formId: res.data.id,
                                fieldId: field.id,
                                name: 'Форма: ' + res.data.name + ' - ' + field.name
                            })
                        }
                    })
                })
            }

            fields.forEach(field => {
                dataConfig.data.data.rows.push(field)
                dataConfig.data.data.columns.push(field)
            })

            setLoading(false)

            setConfig({
                rows: dataConfig.data.data.rows,
                columns: dataConfig.data.data.columns,
                values: dataConfig.data.data.values
            })

            setInfo(dataSettings.data.data)
            if (Object.keys(dataSettings.data.data.settings).length !== 0) {
                setSettings(dataSettings.data.data.settings)
            }

            if (Object.keys(dataSettings.data.data.carsForFilter).length !== 0) {
                setMarks(dataSettings.data.data.carsForFilter.marks)
            }

            if (Object.keys(dataSettings.data.data.carsForFilter).length !== 0) {
                setModelsConfig(dataSettings.data.data.carsForFilter.models)
            }

            // let config_element_columns = []
            // let config_element_rows = []
            // let config_element_values = []
            // config_element_columns = _.xorBy(dataSettings.data.data.settings.columns, dataConfig.data.data.columns, "id")
            // config_element_rows = _.xorBy(dataSettings.data.data.settings.rows, dataConfig.data.data.rows, "id")
            // config_element_values = _.xorBy(dataSettings.data.data.settings.values, dataConfig.data.data.values, "id")

        } catch (e) {
        }
    }

    const getConfig = async () => {
        try {
            const dataConfig = await dispatch(
              authorizationLayer({
                  url: `/analytics/reports/table/config`,
                  method: 'get'
              })
            )

            const dataSettings = await dispatch(
              authorizationLayer({
                  url: `/analytics/reports/${id}`,
                  method: 'get'
              })
            )

            let config_element_columns = []
            let config_element_rows = []
            let config_element_values = []
            config_element_columns = _.xorBy(dataSettings.data.data.settings.columns, dataConfig.data.data.columns, "id")
            config_element_rows = _.xorBy(dataSettings.data.data.settings.rows, dataConfig.data.data.rows, "id")
            config_element_values = _.xorBy(dataSettings.data.data.settings.values, dataConfig.data.data.values, "id")


            setConfig({
                rows: config_element_rows,
                columns: config_element_columns,
                values: config_element_values
            })
        } catch (e) {
        }
    }

    const getModels = async (id) => {
        try {
            let models = modelsConfig.filter(item => item.markId === id)
            setModels(models)
        } catch (e) {
        }
    }

    const updateReportSettings = (el, order, type, action) => {
        let element = []
        let config_element_columns = []
        let config_element_rows = []
        let item = {}
        let new_settings = settings
        let new_config = config
        order = order + 1

        if (action === 'add') {
            if (type === 'column') {
                element = config['columns'].filter(item => item.id === el)
                config_element_columns = new_config['columns'].filter(item => item.id !== el)
                config_element_rows = new_config['rows'].filter(item => item.code !== element[0].code)
                new_config.columns = config_element_columns
                new_config.rows = config_element_rows
            } else if (type === 'row') {
                element = config['rows'].filter(item => item.id === el)
                config_element_rows = new_config['rows'].filter(item => item.id !== el)
                config_element_columns = new_config['columns'].filter(item => item.code !== element[0].code)
                new_config.rows = config_element_rows
                new_config.columns = config_element_columns
            } else if (type === 'value') {
                element = config['values'].filter(item => item.id === el)
            }

            setConfig(new_config)

            item.id = element[0].id
            item.name = element[0].name
            item.title = element[0].name
            item.dataIndex = element[0].name
            item.key = element[0].code
            item.code = element[0].code
            item.order = order

            if (type === 'column') {
                new_settings.columns.push(item)
            } else if (type === 'row') {
                new_settings.rows.push(item)
            } else if (type === 'value') {
                new_settings.values.push({
                    id: item.id,
                    name: item.name,
                    code: item.code
                })
            }
        } else if (action === 'edit') {
            if (type === 'column') {
                element = config['columns'].filter(item => item.id === el)
                new_settings.columns.forEach(setting => {
                    if (setting.order === order) {
                        setting.id = element[0].id
                        setting.name = element[0].name
                        setting.title = element[0].name
                        setting.dataIndex = element[0].name
                        setting.key = element[0].code
                        setting.code = element[0].code
                        setting.order = order
                    }
                })
            } else if (type === 'row') {
                element = config['rows'].filter(item => item.id === el)
                new_settings.rows.forEach(setting => {
                    if (setting.order === order) {
                        setting.id = element[0].id
                        setting.name = element[0].name
                        setting.title = element[0].name
                        setting.dataIndex = element[0].name
                        setting.key = element[0].code
                        setting.code = element[0].code
                        setting.order = order
                    }
                })
            } else if (type === 'value') {
                element = config['values'].filter(item => item.id === el)
                new_settings.values.forEach(setting => {
                    if (setting.id !== el) {
                        setting.id = element[0].id
                        setting.name = element[0].name
                        setting.code = element[0].code
                    }
                })
            }
        } else if (action === 'delete') {
            // getConfig()
            let newArr = []
            if (type === 'column') {
                if (new_settings.columns.length === 1) {
                    newArr = []
                } else {
                    if (el !== undefined) {
                        newArr = new_settings.columns.filter(item => item.id !== el)
                    } else {
                        newArr = new_settings.columns.filter(item => item.order !== order)
                    }
                }

                new_settings.columns = newArr
                if (newArr.length > 0) {
                    new_settings.columns.forEach((item, index) => {
                        item.order = index + 1
                    })
                }
            } else if (type === 'row') {
                if (new_settings.rows.length === 1) {
                    newArr = []
                } else {
                    if (el !== undefined) {
                        newArr = new_settings.rows.filter(item => item.id !== el)
                    } else {
                        newArr = new_settings.rows.filter(item => item.order !== order)
                    }
                }

                new_settings.rows = newArr
                if (newArr.length > 0) {
                    new_settings.rows.forEach((item, index) => {
                        item.order = index + 1
                    })
                }
            } else if (type === 'value') {
                if (new_settings.values.length === 1) {
                    newArr = []
                } else {
                    if (el !== undefined) {
                        newArr = new_settings.values.filter(item => item.id !== el)
                    } else {
                        newArr = new_settings.values.filter(item => item.order !== order)
                    }
                }

                new_settings.values = newArr
                if (newArr.length > 0) {
                    new_settings.values.forEach((item, index) => {
                        item.order = index + 1
                    })
                }
            }
        }

        setSettings(new_settings)
    }

    const submit = async () => {
        try {
            setLoading(true)

            await dispatch(
              authorizationLayer({
                  url: `/analytics/reports/${id}`,
                  method: 'PATCH',
                  data: {
                      name: info.name,
                      processId: info.processId,
                      settings: settings
                  }
              }))
            getInfoByReport()
            setLoading(false)
        } catch (e) {
        }
    }

    const handleSearch = async value => {
        if (value) {
            setLoading(true)
            await dispatch(
              authorizationLayer({
                  url: `analytics/reports/${id}?
                  ${value ? `keyword=${value.trim()}&from=${moment(dates.from).format('YYYY-MM-DD')}&to=${moment(dates.to).format('YYYY-MM-DD')}&markId=${selectedMark}&modelId=${selectedModel}` :
                    `from=${moment(dates.from).format('YYYY-MM-DD')}&to=${moment(dates.to).format('YYYY-MM-DD')}&markId=${selectedMark}&modelId=${selectedModel}`}`,
                  method: 'GET'
              })
            )
              .then(res => {
                  setInfo(res.data.data)
              })
            setLoading(false)
        } else {
            getInfoByReport()
        }
    }

    const onChangeRangePicker = async (dates, dateStrings) => {
        if (dates) {
            setLoading(true)
            setDates({
                from: dateStrings[0],
                to: dateStrings[1]
            })
            await dispatch(
              authorizationLayer({
                  url: `analytics/reports/${id}?${dates ? `from=${moment(dates[0]).format('YYYY-MM-DD')}&to=${moment(dates[1]).format('YYYY-MM-DD')}&markId=${selectedMark}&modelId=${selectedModel}` : ''}`,
                  method: 'GET'
              })
            )
              .then(res => {
                  setInfo(res.data.data)
              })
            setLoading(false)
        } else {
            getInfoByReport()
        }
    };

    const filterMarkAndModel = async (value, type) => {
        if (value) {
            setLoading(true)
            let url = ''
            if (type === 'mark') {
                url = `analytics/reports/${id}?${value ? `markId=${value}&modelId=${selectedModel}&from=${moment(dates.from).format('YYYY-MM-DD')}&to=${moment(dates.to).format('YYYY-MM-DD')}` : `from=${moment(dates.from).format('YYYY-MM-DD')}&to=${moment(dates.to).format('YYYY-MM-DD')}`}`
            } else if (type === 'model') {
                url = `analytics/reports/${id}?${value ? `markId=${selectedMark}&modelId=${value}&from=${moment(dates.from).format('YYYY-MM-DD')}&to=${moment(dates.to).format('YYYY-MM-DD')}` : `from=${moment(dates.from).format('YYYY-MM-DD')}&to=${moment(dates.to).format('YYYY-MM-DD')}`}`
            }

            await dispatch(
              authorizationLayer({
                  url: url,
                  method: 'GET'
              })
            )
              .then(res => {
                  setInfo(res.data.data)
              })
            setLoading(false)
        } else {
            getInfoByReport()
        }
    };

    const timerRefresh = (time) => {
        if (time === null){
            setTime(null)
        } else if (time > 0) {
            setTime(time)
        }
    }

    const menu = (
      <Menu
        items={[
            {
                key: '1',
                label: (
                  <div onClick={() => {
                      timerRefresh(null)
                      setSelectedTime(null)
                  }}>
                      Отключить
                  </div>
                ),
            },
            {
                key: '2',
                label: (
                  <div onClick={() => {
                      timerRefresh(30000)
                      setSelectedTime("30 сек")
                  }}>
                      30 сек
                  </div>
                ),
            },
            {
                key: '3',
                label: (
                  <div onClick={() => {
                      timerRefresh(60000)
                      setSelectedTime("1 мин")
                  }}>
                      1 мин
                  </div>
                ),
            },
            {
                key: '4',
                label: (
                  <div onClick={() => {
                      timerRefresh(300000)
                      setSelectedTime("5 мин")
                  }}>
                      5 мин
                  </div>
                ),
            },
            {
                key: '5',
                label: (
                  <div onClick={() => {
                      timerRefresh(900000)
                      setSelectedTime("15 мин")
                  }}>
                      15 мин
                  </div>
                ),
            },
            {
                key: '6',
                label: (
                  <div onClick={() => {
                      timerRefresh(1800000)
                      setSelectedTime("30 мин")
                  }}>
                      30 мин
                  </div>
                ),
            },
            {
                key: '7',
                label: (
                  <div onClick={() => {
                      timerRefresh(3600000)
                      setSelectedTime("1 ч")
                  }}>
                      1 ч
                  </div>
                ),
            },
            {
                key: '8',
                label: (
                  <div onClick={() => {
                      timerRefresh(7200000)
                      setSelectedTime("2 ч")
                  }}>
                      2 ч
                  </div>
                ),
            },
        ]}
      />
    );

    const inter = useInterval(getInfoByReport, time)

    useEffect(() => {
        getInfoByReport()
    }, [inter])

    const contextData = {
        marks: marks,
        setMarks: setMarks,
        models: models,
        setModels: setModels,
        info: info,
        setInfo: setInfo,
        config: config,
        setConfig: setConfig,
        settings: settings,
        setSettings: setSettings,
        getConfig: getConfig,
        getModels: getModels,
        loading: loading,
        setLoading: setLoading,
        updateReportSettings: updateReportSettings,
        handleSearch: handleSearch,
        onChangeRangePicker: onChangeRangePicker,
        submit: submit,
        dates: dates,
        setSelectedMark: setSelectedMark,
        setSelectedModel: setSelectedModel,
        filterMarkAndModel: filterMarkAndModel,
        selectedTime: selectedTime,
        menu: menu,
        getInfoByReport: getInfoByReport
    }



    return (
      <>
          <Header className='fixed-container'>
              <Row justify='space-between' align='middle'>
                  <Col>
                      <Title level={4} style={{fontWeight: 500}}>
                          {info.name}
                      </Title>
                  </Col>
                  <Col>
                      <Space size={20}>
                          <Button onClick={() => cancel()}>
                              {t('analytics.tabs.reports.configure.close')}
                          </Button>
                      </Space>
                  </Col>
              </Row>
          </Header>
          <Layout>
              <div style={{marginTop: 64}}>
                  <Content style={{padding: '0 0 0 38px', margin: '24px auto 0 ', width: '100%'}}>
                      <Outlet loading={loading} context={contextData}/>
                  </Content>
              </div>
          </Layout>
      </>
    )
}

export default ReportLayout