import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'

import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/es/integration/react'
import { AxiosInterceptor } from './store/actions/methods'

import createAppStore from './store'

import App from './App'
export const { persistor, store } = createAppStore()

ReactDOM.render(
  <BrowserRouter>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
            <AxiosInterceptor>
              <App />
            </AxiosInterceptor>
        </PersistGate>
      </Provider>
  </BrowserRouter>,
  document.getElementById('root'),
)
