import React, {useEffect, useState} from 'react'

import {useDispatch, useSelector} from "react-redux";

import {authorizationLayer, SET_APP} from "@store/actions/methods";

import {
    Layout,
    Table,
    Typography,
    Button,
    Row,
    Col,
    Space,
} from 'antd';

import _ from "lodash";
import queryString from "query-string";

const {Header, Content} = Layout;

const {Title, Text} = Typography;

const SelectProcess = (props) => {
    // useState
    const dispatch = useDispatch();
    const modals = useSelector(state => state.app.modals);
    const index = modals.findIndex(el => el.type === 'select-process');

    const [dataProcess, setDataProcess] = useState([])
    const [selectedRows, setSelectedRows] = useState([])
    const [selectedRowsKeys, setSelectedRowsKeys] = useState([])
    const [loading, setLoading] = useState(false)
    const [disableSubmit, setDisableSubmit] = useState(true)

    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        pageSizeOptions: [10, 50, 100],
    });


    const getData = async () => {
        setLoading(true)
        const q = {
            page: pagination.current,
            take: pagination.pageSize,
            sortElement: 'name'
        };

        const query = queryString.stringify(q);

        const {data} = await dispatch(
            authorizationLayer({url: `processes?${query}`})
        );
        setDataProcess(data);
        setLoading(false)
    };

    useEffect(() => {
        getData();

        if (Object.keys(props.process).length > 0){
            setDisableSubmit(false)
            let pr = {}
            pr.canEditClientCard = props.process.canEditClientCard
            pr.id = props.process.id
            pr.isMain = props.process.isMain
            pr.name = props.process.name
            pr.showClientCard = props.process.showClientCard
            pr.stages = props.process.stages.length

            setSelectedRows([pr])
            setSelectedRowsKeys([pr.id])
        }
    }, [dispatch, pagination]);

    const onClose = () => {
        dispatch(
            SET_APP(
                ['modals'],
                _.filter(modals, c => c.type !== 'select-process')
            )
        )
    }

    const onChange = (pagination) => {
        setPagination(pagination);
    };

    const onSubmit = () => {
        props.selectProcess(selectedRows)
        onClose()
    }

    const columns = [
        {
            title: 'Название процесса',
            dataIndex: 'name'
        },
        {
            title: 'Этапов',
            dataIndex: 'stagesCount'
        },
        {
            title: 'Кем создан',
            dataIndex: 'whoCreated'
        },
        {
            title: 'Время создания',
            dataIndex: 'timeCreated'
        },
        {
            title: 'Действие',
            dataIndex: 'action',
            // render: link => (
            //   <Link to={link} onClick={notification}>
            //     Посмотреть
            //   </Link>
            // )
        }
    ]

    const rowSelection = {
        selectedRowKeys: selectedRowsKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            if (selectedRows?.length > 0 ){
                setDisableSubmit(false)
            } else {
                setDisableSubmit(true)
            }

            setSelectedRows(selectedRows)
            setSelectedRowsKeys(selectedRowKeys)
        },
    }

    return (
    <div className="modal__popup" style={{zIndex: 1000 + index * 2}}>
        <Layout style={{minHeight: '100vh'}}>
            <Header style={{marginBottom: '24px'}}>
                <Row justify="space-between" align="middle">
                    <Col>
                        <Title level={4}>Выберите процесс</Title>
                    </Col>
                    <Col>
                        <Space size={20}>
                            <Button onClick={onClose}>Закрыть</Button>
                            <Button
                                type="primary"
                                onClick={onSubmit}
                                disabled={disableSubmit}
                            >
                                Сохранить
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Header>

            <div
                style={{
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    height: 'calc(100vh - 128px)'
                }}
            >
                <Content
                    style={{
                        maxWidth: '1400px',
                        margin: '0 auto',
                        width: '100%',
                        backgroundColor: "#FFFFFF",
                        borderRadius: "6px",
                    }}
                >
                    <Table
                        loading={loading}
                        rowSelection={{
                            type: 'radio',
                            ...rowSelection
                        }}
                        rowKey='id'
                        columns={columns}
                        dataSource={dataProcess.data}
                        onChange={onChange}
                        pagination={{
                            pagination,
                            total: dataProcess.data && dataProcess.meta.total
                        }}
                    />
                </Content>
            </div>
        </Layout>
    </div>
    )
}

export default SelectProcess
