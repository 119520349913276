import { useEffect } from 'react'
import * as actions from '@store/constants/methods'
import { useNavigate } from 'react-router-dom'
import i18n from '../../i18n'

import AXIOS from 'axios'
import { persistor } from '../../index'

export const LOGOUT = () => {
  return (dispatch) => {
    persistor.purge()
    dispatch(SET_APP(['token'], null))
    dispatch(SET_APP(['user'], null))
  }
}

export const axios = AXIOS.create({
  baseURL: process.env.REACT_APP_BASE_URL,
})

export const AxiosInterceptor = ({ children }) => {
  const navigate = useNavigate()

  useEffect(() => {
    const resInterceptor = (response) => {
      return response
    }

    const errInterceptor = (error) => {
      if (error.response.status === 500) {
        navigate('/Error/ServerError')
      }

      return Promise.reject(error)
    }

    const interceptor = axios.interceptors.response.use(
      resInterceptor,
      errInterceptor,
    )

    return () => axios.interceptors.response.eject(interceptor)
  }, [navigate])

  return children
}

let cancelToken

export const SET_APP = (path, value) => ({
  type: actions.SET_APP,
  path,
  value,
})
export const SET_APP_BY_PARAM = (path, param, value) => ({
  type: actions.SET_APP_BY_PARAM,
  path,
  param,
  value,
})
export const SET_APPS = (arr) => ({ type: actions.SET_APPS, arr })
export const PUSH_APP = (path, value) => ({
  type: actions.PUSH_APP,
  path,
  value,
})
export const UNSHIFT_APP = (path, value) => ({
  type: actions.UNSHIFT_APP,
  path,
  value,
})
export const REMOVE_APP_BY_PARAM = (path, key, value) => ({
  type: actions.REMOVE_APP_BY_PARAM,
  path,
  key,
  value,
})

export const authorizationLayer = (params) => {
  return (dispatch, getState) => {
    const { token } = getState().app

    if (!params.headers)
      params.headers = {
        accepts: 'application/json',
        ['Accept-Language']: i18n.language,
        ['Accept-type']: 'admin',
      }

    if (token) params.headers.Authorization = `Bearer ${token}`

    return axios({
      headers: params.headers,
      method: params.method,
      url: params.url,
      data: params.data,
      cancelToken: params.cancelToken,
      params: params.query,
    }).catch((error) => {
      if (error.response.status === 401) {
        persistor.purge()
        dispatch(SET_APP(['token'], null))
        dispatch(SET_APP(['user'], null))
      }
      if (error.response.status === 409) {
        throw new Error(error.response.data.error)
      }
    })
  }
}

export const GET_DATA =
  (
    url,
    dataName,
    withQuery,
    take = 10,
    page = 1,
    searchArg = '',
    sortElement = '',
    sortBy = 'ASC',
  ) =>
  async (dispatch) => {
    dispatch(SET_APP(['loader'], true))

    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel('Operation canceled due to new request.')
    }

    cancelToken = AXIOS.CancelToken.source()

    try {
      const { data } = await dispatch(
        authorizationLayer({
          url: `/${url}${
            withQuery
              ? `?take=${take}&page=${page}&keyword=${searchArg}&sortElement=${sortElement}&sortBy=${sortBy}`
              : ''
          }`,
          // cancelToken: cancelToken.token,
          method: 'get',
          headers: {
            ['Accept-Language']: i18n.language,
            ['Accept-type']: 'admin'
          },
        }),
      )
      dispatch(SET_APPS([{ path: [dataName], value: data }]))
    } catch (error) {
    } finally {
      await dispatch(SET_APP(['loader'], false))
    }
  }

export const GET_DATA_QUERY =
  ({ url, dataName, method = 'get', query }) =>
  async (dispatch) => {
    dispatch(SET_APP(['loader'], true))

    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel('Operation canceled due to new request.')
    }

    cancelToken = AXIOS.CancelToken.source()

    try {
      const { data } = await dispatch(
        authorizationLayer({
          url: `/${url}`,
          method,
          query,
          headers: {
            ['Accept-Language']: i18n.language,
            ['Accept-type']: 'admin'
          }
        }),
      )
      dispatch(SET_APPS([{ path: [dataName], value: data }]))
    } catch (error) {
    } finally {
      await dispatch(SET_APP(['loader'], false))
    }
  }

export const DELETE_ELEMENT = (url, dataName, id) => async (dispatch) => {
  dispatch(SET_APP(['loader'], true))

  if (typeof cancelToken != typeof undefined) {
    cancelToken.cancel('Operation canceled due to new request.')
  }

  cancelToken = AXIOS.CancelToken.source()

  try {
    await dispatch(
      authorizationLayer({
        url: `/${url}/${id}`,
        // cancelToken: cancelToken.token,
        method: 'delete',
        headers: {
          ['Accept-Language']: i18n.language,
          ['Accept-type']: 'admin'
        }
      }),
    )

    dispatch(GET_DATA(url, dataName, true))
  } catch (error) {
  } finally {
    await dispatch(SET_APP(['loader'], false))
  }
}

export const POST_DATA = (url, formData) => async (dispatch) => {
  dispatch(SET_APP(['loader'], true))
  if (typeof cancelToken != typeof undefined) {
    cancelToken.cancel('Operation canceled due to new request.')
  }

  cancelToken = AXIOS.CancelToken.source()

  try {
    return await dispatch(
      authorizationLayer({
        url: `/${url}`,
        method: 'post',
        data: formData,
        // cancelToken: cancelToken.token
        headers: {
          ['Accept-Language']: i18n.language,
          ['Accept-type']: 'admin'
        }
      }),
    )
  } catch (error) {
    if (error.response.data.statusCode == 409) {
      dispatch(SET_APP(['email_error'], true))
    }
  } finally {
    await dispatch(SET_APP(['loader'], false))
  }
}

export const UPDATE_EDIT_DATA = (url, formData) => async (dispatch) => {
  dispatch(SET_APP(['loader'], true))

  if (typeof cancelToken != typeof undefined) {
    cancelToken.cancel('Operation canceled due to new request.')
  }

  cancelToken = AXIOS.CancelToken.source()

  try {
    return await dispatch(
      authorizationLayer({
        url: `/${url}`,
        // cancelToken: cancelToken.token,
        method: 'patch',
        data: formData,
        headers: {
          ['Accept-Language']: i18n.language,
          ['Accept-type']: 'admin'
        }
      }),
    )
  } catch (error) {
  } finally {
    await dispatch(SET_APP(['loader'], false))
  }
}

export const DUPLICATE_ELEMENT = (url, dataName, id) => async (dispatch) => {
  dispatch(SET_APP(['loader'], true))

  if (typeof cancelToken != typeof undefined) {
    cancelToken.cancel('Operation canceled due to new request.')
  }

  cancelToken = AXIOS.CancelToken.source()

  try {
    await dispatch(
      authorizationLayer({
        url: `/${url}/duplicate/${id}`,
        // cancelToken: cancelToken.token,
        method: 'post',
        headers: {
          ['Accept-Language']: i18n.language,
          ['Accept-type']: 'admin'
        }
      }),
    )

    dispatch(GET_DATA(url, dataName, true))
  } catch (error) {
  } finally {
    await dispatch(SET_APP(['loader'], false))
  }
}
